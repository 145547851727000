import { useContext, useState, useEffect } from 'react'
import { AppContext, log } from '../App'
import * as c from '../c'

export default function Settings({onClose}){
  const App = useContext(AppContext)
  const lang = {...App.lang.global}

  const [frame, setFrame] = useState(0),
        [itensDfe, setItensDfe] = useState([]),
        [itensDiretiva, setItensDiretiva] = useState([]),
        [loading, setLoading] = useState(null),
        [grupos,setGrupos] = useState([])

  const frames = [lang.geral, lang.agendamento, lang.contrato, lang.tarifa, lang.controladoria, lang.auditoria_nfe, lang.auditoria_cte, lang.auditoria_mdfe]

  function get(){
    setLoading(true)
    Promise.all([
      App.api('settings::list'),
      App.api('settings::getGrupoProdutos')
    ]).then(r=>{
      setItensDfe(r[0].results.dfe)
      setItensDiretiva(r[0].results.diretivas)

      setGrupos(r[1].results.map((g,i )=> ({value:g.ID_GRPPRO, label: g.ID_GRPPRO,key:i})))
      setLoading(false)
    })
    App.api('settings::list').then(r=>{
      setItensDfe(r.results.dfe)
      setItensDiretiva(r.results.diretivas)
      setLoading(false)
    })
  }

  async function save(){
    setLoading(true)
    const result = (await App.api('settings::save', {
      settingsDfe: itensDfe,
      settingsDiretivas: itensDiretiva
    }))

    if(result.status){
      const diretivas = await App.api('users::getDiretivas');
      const updatedDiretivas = diretivas?.results.reduce((acc, cur) => ({
        ...acc,
        [cur.NM_DIR]: cur.VL_DIRLC
      }), {});
      App.setApp({
        ...App,
        diretivas: updatedDiretivas
      });
    }

    setLoading(false)
    return result.status
  }

  function settingItemDfe(s, i){
    return <div key={i} className='center-v f g2' style={{minHeight: 38}}>
      <label className='f1'>
        <input type='checkbox' checked={s.CD_STS==='1'}
          onChange={e=>{ itensDfe[i].CD_STS = e.target.checked?'1':'-3'; setItensDfe([...itensDfe]) }}/>
        {s.DS_VLD}
      </label>

      {itensDfe[i].CD_STS === '1' && <c.Select value={parseInt(s.TP_RGR)} className='f1'
        onChange={(e)=>{ itensDfe[i].TP_RGR = e.value; setItensDfe([...itensDfe])}}
        options={[
          {value: 1, label: 'Alertar usuário'},
          {value: 2, label: 'Notificar administrador'},
          {value: 3, label: 'Bloquear'}
        ]}/>
      }
    </div>
  }

  function settingItemDiretiva(d,i){
    return <>
      {d.TP_DIR === '1' &&
        <div key={i} className='p1 center-v f g2 accordion'>
          <label className='f1' >
            <c.Input value={d.VL_DIRLC} info={d.DS_OBS} placeholder={d.VL_DIRLC} label={d.DS_DIR}
              onChange={e=>{d.VL_DIRLC = e.target.value; setItensDiretiva([...itensDiretiva])}} />
          </label>
        </div>}

      {d.TP_DIR === '2' &&
        <div key={i} className='p1 center-v f g2 accordion'>
          <label className='f1'>
          {d.NM_DIR == 'GrupoProdutoPortal' ?
          <c.Select multi label={d.DS_DIR} options={grupos} value={d.VL_DIRLC.split(',').map(g =>g) }
          defaultValue={grupos?.filter(t=>!!d.VL_DIRLC.split(',').map(g => g).find(v=>v===t.value))}
          clearable searchable style={{width: 'small'}}
            onChange={e=>{d.VL_DIRLC = e.map(obj => obj.value).join(','); setItensDiretiva([...itensDiretiva])}}  />
          : <c.Input value={d.VL_DIRLC} type='number' info={d.DS_OBS} placeholder={d.DS_DIR} label={d.DS_DIR}
              onChange={e=>{d.VL_DIRLC = e.target.value;setItensDiretiva([...itensDiretiva])}} />}

          </label>

        </div>}

      {d.TP_DIR === '5' &&
        <div key={i} className='p1 center-v f g2 accordion'>
          <label className='f1'>
            <input type='checkbox' info={d.DS_OBS} checked={d.VL_DIRLC==='T'}
              onChange={e=>{d.VL_DIRLC=e.target.checked?'T':'F';setItensDiretiva([...itensDiretiva])}} /> {d.DS_DIR}
          </label>
        </div>}

      {d.TP_DIR === '7' &&
        <div key={i} className="p1 center-v f g2 accordion">
          <c.Select label={d.DS_DIR} value={d.VL_DIRLC} info={d.DS_OBS} style={{width:'100%', margintop:'100px'}}
            onChange={v=>{d.VL_DIRLC=v.value; setItensDiretiva([...itensDiretiva])}}
            options={d.VL_DIRDOM.split("").map(i => ({value: i.split("=")[0], label: i.split("=")[1]}))}
          />
        </div>}
    </>
  }

  useEffect(()=>get(),[])

  return (
    <c.Modal title={lang.configurations} successMsg={lang.success_save}
      onClose={onClose} onFinish={save} largeWidth loading={loading}
    >
      <div className='f g2 w100'>
        <ul className='menu' style={{gap: 0, marginLeft: -10}}>
          {frames.map((f, fi)=>
            <li key={fi} className={frame===fi ? 'active' : null}><span onClick={()=>setFrame(fi)}>
              <span className='menu-name'>{f}</span>
            </span></li>
          )}
        </ul>

        <div className='f4 f f-column g1'>
          {frame===0 && itensDiretiva.map((s,i)=>s.DS_GRP==='Geral'       && settingItemDiretiva(s,i))}
          {frame===1 && itensDiretiva.map((s,i)=>s.DS_GRP==='Agendamento' && settingItemDiretiva(s,i))}
          {frame===2 && itensDiretiva.map((s,i)=>s.DS_GRP==='Contrato'    && settingItemDiretiva(s,i))}
          {frame===3 && itensDiretiva.map((s,i)=>s.DS_GRP==='Tarifa' && settingItemDiretiva(s,i))}
          {frame===4 && itensDiretiva.map((s,i)=>s.DS_GRP==='Controladoria' && settingItemDiretiva(s,i))}

          {frame===5 && <>
            <div className='center-v f g2'>
              <span className='f1 b'>Validação</span>
              <span className='f1 b'>Tratativa</span>
            </div>
            {itensDfe.map((s,i)=>parseInt(s.TP_XML)===55 ? settingItemDfe(s, i) : null )}
          </>}

          {frame===6 && <>
            <div className='center-v f g2'>
              <span className='f1 b'>Validação</span>
              <span className='f1 b'>Tratativa</span>
            </div>
            {itensDfe.map((s,i)=>parseInt(s.TP_XML)===57 ? settingItemDfe(s, i) : null ) }
          </>}

          {frame===7 && <>
            <div className='center-v f g2'>
              <span className='f1 b'>Validação</span>
              <span className='f1 b'>Tratativa</span>
            </div>
            {itensDfe.map((s,i)=>parseInt(s.TP_XML)===58 ? settingItemDfe(s, i) : null ) }
          </>}
        </div>
      </div>
    </c.Modal>
  )
}
